import React from 'react';

import { CTA, Navbar } from './components';
import { Blog, Features, Footer, Header, Possibility, WhatCCC } from './containers';
import './App.css';

const App = () => {
  return (
    <div className='App'>
        <div className='gradient__bg'>
          <Navbar/>
          <Header/>
        </div>
        {/* Surround components in div to surround background */}
        <div className='whatccc__gradient-bg'>
           <WhatCCC/>
        </div>
        {/* <WhatCCC/> */}
        <div className='black__bg'>
          <Features/>
        </div>
        
        <div className='possibility__gradient-bg'>
          <Possibility/>
        </div>
        <CTA/>
        <Blog/>
        <div className='footer__gradient-bg'>
          <Footer/>
        </div> 
    </div>
  )
}

export default App