import React from 'react';
import { Feature } from '../../components';
import './whatCCC.css';

const WhatCCC = () => {
  return (
    <div className='ccc__whatccc section__margin' id="whatCCC" >
      <div className='ccc__whatccc-feature'>
        <Feature title='The Community Chess Club' text='We are a diverse group of friends who want to do amazing things in the world. We love and care for one another and want to share this friendship with everyone. We are creators and this is where we go to swim in imagination before showcasing our ideas.'/>
      </div>
      <div className='ccc__whatccc-heading'>
        <h1 className='ccc__whatccc-gradient__text'>
          Possibilities Beyond Imagination
        </h1>
        <p>Explore The Library</p>
      </div>
      <div className='ccc__whatccc-container'>
        <Feature title='Vulnerability' text='Being vulnerable with each other allows our connection to deepen. This begins by accepting yourself in all that you are and realizing there is nothing to be embarrassed about. We will support you always'/>
        <Feature title='Action' text='We value action over everything. Fuck a plan, get started and we can figure it out as we go. Everything you do will have a positive effect even if it does not turn out how you initially though. Go for it today.'/>
        <Feature title='Honesty' text='Being honest with friends is hard and so is being honest with yourself. Through meditation and journaling, I am beginning to feel pretty comfortable with myself, which enables me to be aware and honest with how I am feeling.'/>
      </div>
    </div>
  )
}

export default WhatCCC