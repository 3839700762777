import React from 'react';
import { Article } from '../../components';  
import { paraglide, Anubis, ConceptOfTime, Kaleidoscope, WalkingGuy, blogPic } from './imports';
import './blog.css';

const Blog = () => {
  return (
    <div className='ccc__blog section__padding' id='blog'>
      <div className='ccc__blog-heading'>
        <h1 className='ccc__blog-gradient__text'>Stuff we are doing</h1>
      </div>
      <div className='ccc__blog-container'>
        {/* <div className='ccc__blog-container__groupA'>
          <Article imgUrl={ConceptOfTime} date='July 18, 2022' title='We are obsessed with concept of time'/>
        </div> */}
        <div className='ccc__blog-container__groupB'>
          <Article imgUrl={ConceptOfTime} date='July 18, 2022' title='We are obsessed with concept of time'/>
          <Article imgUrl={paraglide} date='July 18, 2022' title='Trying something new'/>
          <Article imgUrl={blogPic} date='July 18, 2022' title='Discovering applications for artificial intelligence'/>
          <Article imgUrl={Kaleidoscope} date='July 18, 2022' title='Discussion on psychadelics'/>
          <Article imgUrl={WalkingGuy} date='July 18, 2022' title='Going on walks to escape'/>
        </div>
      </div>
    </div>
  )
}

export default Blog